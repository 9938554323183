<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        :style="{ backgroundImage: `url(${backgroundImage})` }"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center">
            <img src="/media/logos/metaceviz-logo.png" class="max-h-140px" alt="" />
          </a>
        </div>
      </div>
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-10 mx-15"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form class="form" novalidate="novalidate" id="kt_login_signin_form">
              <div class="pb-13 pt-lg-0 pt-5"></div>
              <b-form-group class="text-center" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  v-model="selected"
                  :options="[
                    { text: 'ÖĞRENCİ', value: 'student' },
                    { text: 'VELİ', value: 'customer' }
                  ]"
                  :aria-describedby="ariaDescribedby"
                  button-variant="outline-primary"
                  size="lg"
                  buttons
                ></b-form-radio-group>
              </b-form-group>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">Kimlik Numarası</label>
                <div id="example-input-group-1" label="" label-for="example-input-1">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="Email"
                    v-model="form.Email"
                    style="background-image: none"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">Şifre</label>
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >Şifremi Unuttum</a
                  >
                </div>
                <div id="example-input-group-2">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="Password"
                    ref="password"
                    v-model="form.Password"
                    autocomplete="off"
                    style="background-image: none"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">Okul</label>
                <c-tenant></c-tenant>
              </div>
              <div class="pb-5 d-flex justify-content-center">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4"
                >
                  Giriş Yap
                </button>
              </div>
            </form>
          </div>
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                  Şifre Sıfırlama
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Kimlik numarası ve telefon bilgisi giriniz. Kimlik numaranızın kayıtlarda
                  bulunması halinde telefonunuza SMS gönderilecektir.
                </p>
              </div>
              <div class="form-group" v-show="!formForgot.codesent">
                <label class="font-size-h6 font-weight-bolder text-dark">Okul</label>
                <c-tenant></c-tenant>
              </div>
              <div class="form-group" v-show="!formForgot.codesent">
                <label class="font-size-h6 font-weight-bolder text-dark">Kimlik Numarası</label>
                <div id="example-input-group-1" label-for="example-input-1">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="ForgotCitizenNumber"
                    v-model="formForgot.CitizenNumber"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="form-group" v-show="!formForgot.codesent">
                <label class="font-size-h6 font-weight-bolder text-dark">Telefon Numarası</label>
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  name="ForgotPhoneNumber"
                  autocomplete="off"
                  v-model="formForgot.PhoneNumber"
                />
              </div>
              <div class="form-group" v-show="formForgot.codesent">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="number"
                  v-model="formForgot.Code"
                  placeholder="Kod"
                  name="ForgotCode"
                  autocomplete="off"
                />
              </div>
              <div class="form-group" v-show="formForgot.codesent">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  v-model="formForgot.Password"
                  placeholder="Yeni şifreniz"
                  name="ForgotPassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group" v-show="formForgot.codesent">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  v-model="formForgot.ConfirmPassword"
                  placeholder="Tekrar yeni şifreniz"
                  name="ForgotConfirmPassword"
                  autocomplete="off"
                />
              </div>

              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  ref="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Gönder
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  İptal
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center py-7 py-lg-0"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/pages/login/login-1.scss';
</style>

<script>
import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core'

// FormValidation plugins
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger'
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap'
import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton'
import KTUtil from '@/assets/js/components/util'
import { mapState } from 'vuex'
import { LOGIN, LOGOUT, LOGINBYKEY } from '@/core/services/store/auth.module'
import Swal from 'sweetalert2'
import ApiService from '@/core/services/api.service.js'

export default {
  name: 'login-1',
  data() {
    return {
      state: 'signin',
      form: {
        Email: '',
        Password: ''
      },
      formForgot: {
        codesent: false,
        PhoneNumber: '',
        CitizenNumber: '',
        Password: '',
        ConfirmPassword: '',
        Code: ''
      },
      selected: 'student'
    }
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors
    }),

    backgroundImage() {
      return process.env.BASE_URL + 'media/bg/bg-3.jpg'
    }
  },
  watch: {
    selected() {
      this.form = {
        Email: '',
        Password: ''
      }
    }
  },
  created() {
    this.$store.dispatch(LOGOUT)
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const key = urlParams.get('key')
    if (key != null && key.length > 10) {
      this.$store.dispatch(LOGINBYKEY, { key }).then((result) => {
        if (result.Token) this.$router.push({ name: 'dashboard' })
      })
    }
  },
  mounted() {
    const signin_form = KTUtil.getById('kt_login_signin_form')
    const forgot_form = KTUtil.getById('kt_login_forgot_form')
    this.fv = formValidation(signin_form, {
      fields: {
        Email: {
          validators: {
            notEmpty: {
              message: 'Kimlik numarası gereklidir'
            }
          }
        },
        Password: {
          validators: {
            notEmpty: {
              message: 'Şifre gereklidir'
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    })

    this.fv.on('core.form.valid', () => {
      var email = this.form.Email
      var password = this.form.Password
      const submitButton = this.$refs['kt_login_signin_submit']
      this.setLoading(submitButton)
      // clear existing errors
      this.$store.dispatch(LOGOUT)
      // set spinner to submit button
      this.$store
        .dispatch(LOGIN, { email, password })
        // go to which page after successfully login
        .then((result) => {
          if (result.Token) this.$router.push(this.$route.query.redirect || 'dashboard')
          else this.removeLoading(submitButton)
        })
        .catch(() => {
          this.removeLoading(submitButton)
        })
    })

    this.fv.on('core.form.invalid', () => {
      Swal.fire({
        title: '',
        text: 'Lütfen formdaki hataları düzeltiniz',
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary',
        heightAuto: false
      })
    })

    this.fv2 = formValidation(forgot_form, {
      fields: {
        ForgotCitizenNumber: {
          validators: {
            notEmpty: {
              message: 'Kimlik numarası gereklidir'
            }
          }
        },
        ForgotPhoneNumber: {
          validators: {
            notEmpty: {
              message: 'Telefon numarası gereklidir'
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    })
    this.fv2.on('core.form.valid', () => {
      const submitButton = this.$refs['kt_login_forgot_submit']
      this.setLoading(submitButton)
      if (!this.formForgot.codesent) {
        ApiService.setHeader()
        ApiService.put('api/verifylogin', this.formForgot)
          .then((result) => {
            this.formForgot.codesent = result.data == true && result.status == 200
            this.removeLoading(submitButton)
          })
          .catch(({ response }) => {
            ApiService.showError(response)
            this.removeLoading(submitButton)
          })
      } else {
        ApiService.setHeader()
        ApiService.put('api/changepassword', this.formForgot)
          .then((result) => {
            if (result.status && result.status != 200) this.removeLoading(submitButton)
            else {
              Swal.fire({
                title: 'Doğrulama',
                text: 'Şifreniz başarıyla güncellendi. Kimlik numarası ve şifreniz ile giriş yapabilirsiniz.',
                icon: 'success',
                confirmButtonClass: 'btn btn-secondary',
                heightAuto: false
              })
              this.$router.push({ name: 'dashboard' })
            }
          })
          .catch(({ response }) => {
            ApiService.showError(response)
            this.removeLoading(submitButton)
          })
      }
    })

    this.fv2.on('core.form.invalid', () => {
      Swal.fire({
        title: '',
        text: 'Lütfen formdaki hataları düzeltiniz',
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary',
        heightAuto: false
      })
    })
  },
  methods: {
    setLoading(button) {
      button.disabled = true
      button.classList.add('spinner', 'spinner-light', 'spinner-right')
    },
    removeLoading(button) {
      button.disabled = false
      button.classList.remove('spinner', 'spinner-light', 'spinner-right')
    },
    showForm(form) {
      this.state = form
      var form_name = 'kt_login_' + form + '_form'
      KTUtil.animateClass(KTUtil.getById(form_name), 'animate__animated animate__backInUp')
    }
  }
}
</script>
