<template>
  <div v-if="appName != null">
    <MetaCevizLogin v-if="appName == 'MetaCeviz'"></MetaCevizLogin>
    <CoreLogin v-else></CoreLogin>
  </div>
</template>

<script>
import { App } from '@capacitor/app'
import MetaCevizLogin from '@/view/login/MetaCevizLogin'
import CoreLogin from '@/view/login/CoreLogin'

export default {
  name: 'CommonLogin',
  data() {
    return {
      appName: null
    }
  },
  components: {
    MetaCevizLogin,
    CoreLogin
  },
  async mounted() {
    await this.getAppInfo()
  },
  methods: {
    async getAppInfo() {
      const appInfo = await App.getInfo()
      this.appName = appInfo.name
    }
  }
}
</script>
