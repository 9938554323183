var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.appName != null
    ? _c(
        "div",
        [_vm.appName == "MetaCeviz" ? _c("MetaCevizLogin") : _c("CoreLogin")],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }